@import url('https://fonts.cdnfonts.com/css/sf-pro-display');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'SF Pro Display', sans-serif;
  background: #0F172A;
  min-height: 100vh;
}
